document.addEventListener("DOMContentLoaded", function () {
  const blocks = document.querySelectorAll(".content-interactive-circle");

  function isMobile() {
    return window.innerWidth < 768;
  }

  blocks.forEach(function (block) {
    let isCurrentlyMobile = isMobile();

    const icons = block.querySelectorAll(
      ".content-interactive-circle__circle-icon"
    );
    const contents = block.querySelectorAll(
      ".content-interactive-circle__circle-content"
    );
    const valueProps = block.querySelectorAll(
      ".content-interactive-circle__value-prop"
    );
    const tabs = block.querySelectorAll(".content-interactive-circle__tab");

    function activateContent(index) {
      icons.forEach(function (icon) {
        icon.classList.remove(
          "content-interactive-circle__circle-icon--active"
        );
      });
      contents.forEach(function (content) {
        content.classList.remove(
          "content-interactive-circle__circle-content--active"
        );
      });
      valueProps.forEach(function (prop) {
        prop.classList.remove("content-interactive-circle__value-prop--active");
      });

      const activeIcon = block.querySelector(
        '.content-interactive-circle__circle-icon[data-index="' + index + '"]'
      );
      if (activeIcon) {
        activeIcon.classList.add(
          "content-interactive-circle__circle-icon--active"
        );
      }
      const activeContent = block.querySelector(
        '.content-interactive-circle__circle-content[data-index="' +
          index +
          '"]'
      );
      if (activeContent) {
        activeContent.classList.add(
          "content-interactive-circle__circle-content--active"
        );
      }
      const activeValueProp = block.querySelector(
        '.content-interactive-circle__value-prop[data-index="' + index + '"]'
      );
      if (activeValueProp) {
        activeValueProp.classList.add(
          "content-interactive-circle__value-prop--active"
        );
      }
    }

    function toggleAccordion(tab) {
      const isExpanded = tab.classList.contains(
        "content-interactive-circle__tab--expanded"
      );
      tabs.forEach(function (otherTab) {
        otherTab.classList.remove("content-interactive-circle__tab--expanded");
      });
      if (!isExpanded) {
        tab.classList.add("content-interactive-circle__tab--expanded");
      }
    }

    function setupDesktopEvents() {
      icons.forEach(function (icon) {
        icon.addEventListener("click", iconClickHandler);
      });
      valueProps.forEach(function (prop) {
        prop.addEventListener("click", valuePropClickHandler);
      });
    }

    function removeDesktopEvents() {
      icons.forEach(function (icon) {
        icon.removeEventListener("click", iconClickHandler);
      });
      valueProps.forEach(function (prop) {
        prop.removeEventListener("click", valuePropClickHandler);
      });
    }

    function setupMobileEvents() {
      tabs.forEach(function (tab) {
        const title = tab.querySelector(
          ".content-interactive-circle__tab-title"
        );
        title.addEventListener("click", tabClickHandler);
      });
    }

    function removeMobileEvents() {
      tabs.forEach(function (tab) {
        const title = tab.querySelector(
          ".content-interactive-circle__tab-title"
        );
        title.removeEventListener("click", tabClickHandler);
      });
    }

    function iconClickHandler() {
      const index = this.getAttribute("data-index");
      activateContent(index);
    }

    function valuePropClickHandler() {
      const index = this.getAttribute("data-index");
      activateContent(index);
    }

    function tabClickHandler() {
      const tab = this.parentElement;
      toggleAccordion(tab);
    }

    function initialize() {
      if (isCurrentlyMobile) {
        setupMobileEvents();
      } else {
        setupDesktopEvents();
      }
    }

    function handleResize() {
      if (isMobile() && !isCurrentlyMobile) {
        removeDesktopEvents();
        setupMobileEvents();
        isCurrentlyMobile = true;
      } else if (!isMobile() && isCurrentlyMobile) {
        removeMobileEvents();
        setupDesktopEvents();
        isCurrentlyMobile = false;
      }
    }

    initialize();
    window.addEventListener("resize", handleResize);
  });
});
