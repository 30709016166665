document.addEventListener("DOMContentLoaded", function () {
  const tabNavItems = document.querySelectorAll(
    ".content-categories-tabs__nav-li"
  );
  const tabContents = document.querySelectorAll(
    ".content-categories-tabs__tab"
  );
  const tabContentMobileItems = document.querySelectorAll(
    ".content-categories-tabs__tab--mobile .content-categories-tabs__tab-title"
  );
  const tabContentMobiles = document.querySelectorAll(
    ".content-categories-tabs__tab--mobile .content-categories-tabs__content"
  );

  tabNavItems.forEach((tabNav, index) => {
    tabNav.addEventListener("click", () => {
      if (window.innerWidth > 1000) {
        tabNavItems.forEach((item, i) => {
          item.classList.remove("active");
          tabContents[i].classList.remove("active");
        });
        tabNav.classList.add("active");
        tabContents[index].classList.add("active");
      }
    });
  });

  if (
    window.innerWidth > 1000 &&
    tabNavItems.length > 0 &&
    tabContents.length > 0
  ) {
    tabNavItems[0].classList.add("active");
    tabContents[0].classList.add("active");
  }

  tabContentMobileItems.forEach((tabTitle, index) => {
    tabTitle.addEventListener("click", () => {
      if (window.innerWidth <= 1000) {
        const content = tabContentMobiles[index];
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
          tabTitle.classList.remove("active");
        } else {
          tabContentMobiles.forEach((el, i) => {
            el.style.maxHeight = null;
            tabContentMobileItems[i].classList.remove("active");
          });
          content.style.maxHeight = content.scrollHeight + "px";
          tabTitle.classList.add("active");
        }
      }
    });
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1000) {
      tabContentMobiles.forEach((content, i) => {
        content.style.maxHeight = null;
        tabContentMobileItems[i].classList.remove("active");
      });
      tabNavItems.forEach((tab, i) => {
        tab.classList.remove("active");
        tabContents[i].classList.remove("active");
      });
      if (tabNavItems.length > 0 && tabContents.length > 0) {
        tabNavItems[0].classList.add("active");
        tabContents[0].classList.add("active");
      }
    } else {
      tabContentMobileItems.forEach((tabTitle, index) => {
        const content = tabContentMobiles[index];
        if (content.style.maxHeight) {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  });
});
document.addEventListener("DOMContentLoaded", function () {
  const subcategoryLists = document.querySelectorAll(
    ".content-categories-tabs__categories-group-ul"
  );

  subcategoryLists.forEach((list) => {
    const subcategoryItems = list.querySelectorAll("li");

    if (subcategoryItems.length > 0) {
      subcategoryItems[0].classList.add("active");
    }

    subcategoryItems.forEach((item) => {
      item.addEventListener("mouseover", function () {
        subcategoryItems.forEach((subItem) =>
          subItem.classList.remove("active")
        );
        this.classList.add("active");
      });
    });
  });
});
