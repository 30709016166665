document.addEventListener("DOMContentLoaded", function () {
  const tabNavItems = Array.from(
    document.querySelectorAll(".content-tabs-with-card-grid__nav-li")
  ).filter(
    (item) =>
      !item.classList.contains("content-tabs-with-card-grid__nav-li--view-all")
  );
  const tabContents = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab"
  );
  const tabContentMobileItems = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab--mobile .content-tabs-with-card-grid__tab-title"
  );
  const tabContentMobiles = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab--mobile .content-tabs-with-card-grid__content"
  );

  tabNavItems.forEach((tabNav, index) => {
    tabNav.addEventListener("click", () => {
      if (window.innerWidth > 1200) {
        tabNavItems.forEach((item, i) => {
          item.classList.remove("active");
          tabContents[i].classList.remove("active");
        });
        tabNav.classList.add("active");
        tabContents[index].classList.add("active");
      }
    });
  });

  if (window.innerWidth > 1200 && tabNavItems.length && tabContents.length) {
    tabNavItems[0].classList.add("active");
    tabContents[0].classList.add("active");
  }

  tabContentMobileItems.forEach((tabTitle, index) => {
    tabTitle.addEventListener("click", () => {
      if (window.innerWidth <= 1200) {
        const contentContainer = tabContentMobiles[index].parentElement;
        const content = tabContentMobiles[index];
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
          tabTitle.classList.remove("active");
          contentContainer.classList.remove("active");
        } else {
          tabContentMobiles.forEach((el, i) => {
            el.style.maxHeight = null;
            tabContentMobileItems[i].classList.remove("active");
            el.parentElement.classList.remove("active");
          });
          content.style.maxHeight = content.scrollHeight + "px";
          tabTitle.classList.add("active");
          contentContainer.classList.add("active");
        }
      }
    });
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1200) {
      tabContentMobiles.forEach((content, i) => {
        content.style.maxHeight = null;
        tabContentMobileItems[i].classList.remove("active");
        content.parentElement.classList.remove("active");
      });
      tabNavItems.forEach((tab, i) => {
        tab.classList.remove("active");
        tabContents[i].classList.remove("active");
      });
      if (tabNavItems.length && tabContents.length) {
        tabNavItems[0].classList.add("active");
        tabContents[0].classList.add("active");
      }
    } else {
      tabContentMobiles.forEach((content) => {
        if (content.style.maxHeight) {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  });
});
