import Swiper from "swiper/bundle";
import "swiper/css/bundle";

document.addEventListener("DOMContentLoaded", () => {
  const mobileSwipers = document.querySelectorAll(
    ".content-alternating-rows__mobile .swiper-container"
  );

  mobileSwipers.forEach((container) => {
    let swiperInstance;

    const initSwiper = () => {
      if (window.innerWidth < 768 && !swiperInstance) {
        swiperInstance = new Swiper(container, {
          direction: "horizontal",
          slidesPerView: 1,
          spaceBetween: 0,
          slidesPerGroup: 1,
          navigation: {
            nextEl: container.parentElement.querySelector(
              ".swiper-button-next"
            ),
            prevEl: container.parentElement.querySelector(
              ".swiper-button-prev"
            ),
          },
          pagination: {
            el: container.parentElement.querySelector(".swiper-pagination"),
            clickable: true,
          },
          loop: false,
        });
      } else if (window.innerWidth >= 768 && swiperInstance) {
        swiperInstance.destroy(true, true);
        swiperInstance = null;
      }
    };

    initSwiper();

    window.addEventListener("resize", () => {
      initSwiper();
    });
  });
});
