document.addEventListener("DOMContentLoaded", function () {
  const tabNavItems = Array.from(
    document.querySelectorAll(".content-tabs-with-card-grid__nav-li")
  ).filter(
    (item) =>
      !item.classList.contains("content-tabs-with-card-grid__nav-li--view-all")
  );

  const tabContents = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab"
  );
  const tabContentMobileItems = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab--mobile .content-tabs-with-card-grid__tab-title"
  );
  const tabContentMobiles = document.querySelectorAll(
    ".content-tabs-with-card-grid__tab--mobile .content-tabs-with-card-grid__content"
  );

  function handleTabSwitching() {
    if (window.innerWidth > 1200) {
      if (tabNavItems.length > 0 && tabContents.length > 0) {
        tabNavItems.forEach((tabNav, index) => {
          tabNav.addEventListener("click", () => {
            tabContents.forEach((tabContent, i) => {
              tabNavItems[i].classList.remove("active");
              tabContent.classList.remove("active");
            });

            tabContents[index].classList.add("active");
            tabNav.classList.add("active");
          });
        });

        tabContents[0].classList.add("active");
        tabNavItems[0].classList.add("active");
      }
    } else {
      if (tabContentMobileItems.length > 0) {
        tabContentMobileItems.forEach((tabTitle, index) => {
          tabTitle.addEventListener("click", () => {
            const contentContainer = tabContentMobiles[index].parentElement;
            const content = tabContentMobiles[index];

            if (content.style.maxHeight) {
              content.style.maxHeight = null;
              tabTitle.classList.remove("active");
              contentContainer.classList.remove("active");
            } else {
              tabContentMobiles.forEach((el, i) => {
                el.style.maxHeight = null;
                tabContentMobileItems[i].classList.remove("active");
                el.parentElement.classList.remove("active");
              });

              content.style.maxHeight = content.scrollHeight + "px";
              tabTitle.classList.add("active");
              contentContainer.classList.add("active");
            }
          });
        });
      }
    }
  }

  window.addEventListener("resize", handleTabSwitching);
  handleTabSwitching();
});
