import $ from "jquery";

$(function () {
  const windowWidth = $(window).width();
  if (windowWidth < 1200) {
    $(".content-hover-columns__column-mobile").each((index, blockElem) => {
      $(blockElem).on("click", showContent);
    });

    function showContent() {
      const contentHidden = $(this)
        .parent()
        .children(".content-hover-columns__column-flex-wrapper");

      if (contentHidden.hasClass("is-open")) {
        contentHidden.css("display", "none");
        contentHidden.removeClass("is-open");
        $(this).parent().removeClass("is-open");
      } else {
        contentHidden.css("display", "block");
        contentHidden.addClass("is-open");
        $(this).parent().addClass("is-open");
      }
    }
  }
});
