document.addEventListener("DOMContentLoaded", function () {
  function handleOverlayClick(event) {
    event.currentTarget.classList.add("show-overlay-mobile");
  }

  document
    .querySelectorAll(".content-mason-grid-w-subtitle")
    .forEach((blockElem, index) => {
      const align = blockElem.dataset.align;
      blockElem.classList.add("align" + (align || "full"));

      if (window.innerWidth < 1023) {
        document
          .querySelectorAll(".content-mason-grid-w-subtitle-item")
          .forEach((item) => {
            item.addEventListener("click", handleOverlayClick);
          });
      }
    });
});
