import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import "swiper/css/thumbs";

document.addEventListener("DOMContentLoaded", () => {
  const sliderWrapper = document.querySelector(
    ".wp-block-tms-blocks-tms-img-gallery-thumbs"
  );

  if (sliderWrapper) {
    const sliders = sliderWrapper.querySelectorAll(
      ".tms-img-gallery-thumbs__slider"
    );

    if (sliders.length > 0) {
      sliders.forEach((slider) => {
        if (!slider.classList.contains("swiper-initialized")) {
          const slidesToShow = parseInt(slider.dataset.slidesToShow, 10) || 1;
          const slidesToScroll =
            parseInt(slider.dataset.slidesToScroll, 10) || 1;
          const autoplay = slider.dataset.autoplay === "true";
          const autoplaySpeed =
            parseInt(slider.dataset.autoplaySpeed, 10) || 3000;
          const dots = slider.dataset.dots === "true";
          const infinite = slider.dataset.infinite === "true";
          const speed = parseInt(slider.dataset.speed, 10) || 750;
          const arrows = slider.dataset.arrows === "true";

          const sliderThumb = document.querySelectorAll(
            ".tms-img-gallery-thumbs__thumbnail"
          );

          console.log("arrows", arrows);

          const swiperThumb = new Swiper(sliderThumb[0], {
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
          });

          const swiper = new Swiper(slider, {
            direction: "horizontal",
            loop: infinite,
            autoplay: autoplay
              ? { delay: autoplaySpeed, disableOnInteraction: false }
              : false,
            slidesPerView: slidesToShow,
            slidesPerGroup: slidesToScroll,
            speed: speed,

            pagination: dots
              ? {
                  el: sliderWrapper.querySelector(".swiper-pagination"),
                  clickable: true,
                }
              : false,

            navigation: arrows
              ? {
                  nextEl: sliderWrapper.querySelector(".swiper-button-next"),
                  prevEl: sliderWrapper.querySelector(".swiper-button-prev"),
                }
              : false,

            scrollbar: {
              el: ".swiper-scrollbar",
            },
            thumbs: {
              swiper: swiperThumb,
            },
          });

          swiper.on("slideChange", function () {});

          slider.classList.add("swiper-initialized");
        }
      });
    }
  }
});
