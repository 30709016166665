document.addEventListener("DOMContentLoaded", function () {
  const blocks = document.querySelectorAll(".tms-blocks-hero-video");

  blocks.forEach(function (blockElem, blockIndex) {
    let videoElem = blockElem.querySelector(
      ".wp-block-video.hero-video__video"
    );
    if (!videoElem) {
      videoElem = blockElem.querySelector(".wp-block-embed.is-type-video");
    }
    if (!videoElem) {
      return;
    }

    const videoTag = videoElem.querySelector("video");
    const iframeTag = videoElem.querySelector("iframe");
    const hasVideo = videoTag !== null;
    const hasIframe = iframeTag !== null;

    if (!hasVideo && !hasIframe) {
      blockElem.classList.add("no-overlay");
      const overlayBlock = blockElem.querySelector(
        ".wp-block-tms-blocks-hero-video-overlay"
      );
      if (overlayBlock) overlayBlock.classList.add("no-overlay");
      const overlayImg = blockElem.querySelector(".hero-video-overlay__img");
      if (overlayImg) overlayImg.classList.add("no-video");
      const overlayText = blockElem.querySelector(".hero-video-overlay__text");
      if (overlayText) overlayText.innerHTML = "Please, add a video";
    } else {
      const overlayImg = blockElem.querySelector(".hero-video-overlay__img");
      if (overlayImg) {
        overlayImg.style.height = videoElem.offsetHeight + "px";
        overlayImg.style.width = videoElem.offsetWidth + "px";
        const videoStyles = window.getComputedStyle(videoElem);
        const paddingLeft = videoStyles.getPropertyValue("padding-left");
        const marginLeft = videoStyles.getPropertyValue("margin-left");
        overlayImg.style.left = `calc(${paddingLeft} + ${marginLeft})`;
      } else {
      }

      const overlayContainer = blockElem.querySelector(
        ".wp-block-tms-blocks-hero-video-overlay"
      );
      if (overlayContainer) {
        const handleOverlayClick = function (event) {
          const overlayElement = blockElem.querySelector(
            ".hero-video-overlay__overlay"
          );
          const svgWrapper = blockElem.querySelector(
            ".hero-video-overlay__preview-svg-wrapper"
          );
          if (overlayElement) {
            overlayElement.classList.add("is-active");
          }
          if (svgWrapper) {
            svgWrapper.classList.add("is-active");
          }

          videoElem.classList.add("no-overlay");
          const overlayTextInside = videoElem.querySelector(
            ".hero-video-overlay__text"
          );
          if (overlayTextInside) overlayTextInside.classList.add("no-overlay");
          videoElem.style.position = "relative";
          videoElem.style.zIndex = "10";

          if (videoTag) {
            videoTag.play();
          } else if (iframeTag) {
            const classes = videoElem.className.split(" ");
            const videoProviderClass = classes.find((cls) =>
              cls.startsWith("is-provider-")
            );

            if (iframeTag) {
              const src = iframeTag.getAttribute("src");
              if (src) {
                const isPaused = src.includes("autoplay=0");
                const newSrc = isPaused
                  ? src.replace("autoplay=0", "autoplay=1")
                  : src + "&autoplay=1";
                iframeTag.setAttribute("src", newSrc);
              }
            }
          }
          overlayContainer.removeEventListener("click", handleOverlayClick);
        };

        overlayContainer.addEventListener("click", handleOverlayClick);
      } else {
        blockElem.classList.add("no-overlay");
        const overlayBlock = blockElem.querySelector(
          ".wp-block-tms-blocks-hero-video-overlay"
        );
        if (overlayBlock) overlayBlock.classList.add("no-overlay");
        videoElem.style.position = "relative";
      }
    }
  });
});
