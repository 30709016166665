document.addEventListener("DOMContentLoaded", function () {
  const triggers = document.querySelectorAll(
    ".cta-button-modal__button[data-modal-id]"
  );

  triggers.forEach((trigger) => {
    const modalId = trigger.getAttribute("data-modal-id");
    const modal = document.getElementById(modalId);
    if (!modal) return;

    const closeBtn = modal.querySelector(".cta-button-modal__close");
    const modalContent = modal.querySelector(
      ".cta-button-modal__modal-content"
    );

    trigger.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      modal.style.display = "block";
      document.body.classList.add("cta-button-modal__open-body");
    });

    closeBtn.addEventListener("click", (e) => {
      e.stopPropagation();
      modal.style.display = "none";
      document.body.classList.remove("cta-button-modal__open-body");
    });

    window.addEventListener("click", (e) => {
      if (modal.style.display === "block") {
        if (
          modalContent &&
          !modalContent.contains(e.target) &&
          e.target !== trigger
        ) {
          modal.style.display = "none";
          document.body.classList.remove("cta-button-modal__open-body");
        }
      }
    });

    document.addEventListener("keydown", (e) => {
      if (
        (e.key === "Escape" || e.keyCode === 27) &&
        modal.style.display === "block"
      ) {
        modal.style.display = "none";
        document.body.classList.remove("cta-button-modal__open-body");
      }
    });
  });
});
