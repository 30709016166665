import "./blocks/heroslider/singleslider/swiper-init";

import "./blocks/blog-grid-of-image-and-preview-text/swiper-init";

import "./blocks/blog-cards-over-background-image/swiper-init";

import "./blocks/content-alternating-rows/view";

import "./blocks/content-buckets-large/view";
import "./blocks/content-buckets-large/swiper-init";

import "./blocks/content-buckets-medium/view";

import "./blocks/content-carousel-of-images-with-subtitle/content-carousel/view";

import "./blocks/content-clickable-global-map/view";

import "./blocks/content-hover-columns/view";

import "./blocks/content-icon-list/view";

import "./blocks/content-interactive-circle/view";

import "./blocks/content-logo-carousel/logo-carousel/view";

import "./blocks/content-logo-grid/view";
import "./blocks/content-logo-grid/swiper-init";

import "./blocks/content-mason-grid-w-subtitle/view";
import "./blocks/content-mason-grid-w-subtitle/swiper-init";
import "./blocks/content-mason-grid-w-subtitle/script";

import "./blocks/hero-buckets-hover/hero-bucket/view";

import "./blocks/hero-video/view";
import "./blocks/hero-video/hero-video-overlay/view";

import "./blocks/hero-with-simple-bottom-subcategories/view";

import "./blocks/search-block/view";

import "./blocks/testimonial-carousel/swiper-init";

import "./blocks/content-tabs/script";

import "./blocks/content-categories-tabs/script";

import "./blocks/content-tabs-with-card-grid/script";

import "./blocks/content-tabs-left-image-text-right/script";

import "./blocks/cta-button-modal/view";

import "./tms-block-frontend.scss";
