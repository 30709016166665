document.addEventListener("DOMContentLoaded", function () {
  const accordionItems = document.querySelectorAll(".accordion-item");

  accordionItems.forEach((item) => {
    const title = item.querySelector(".accordion-item__title");
    if (title) {
      title.addEventListener("click", function () {
        const isOpen = item.classList.contains("is-open");

        accordionItems.forEach((otherItem) => {
          otherItem.classList.remove("is-open");
        });

        if (!isOpen) {
          item.classList.add("is-open");
        }
      });
    }
  });
});
