import $ from "jquery";

$(function () {
  function handleOverlayClick() {
    $(this).addClass("show-overlay-mobile");
  }

  $(".content-buckets-large").each((index, blockElem) => {
    const align = $(blockElem).data("align");
    $(blockElem).addClass("align" + (align ?? "full"));

    if (window.innerWidth < 1023) {
      $(".content-buckets-large-item").on("click", handleOverlayClick);
    }
  });
});
