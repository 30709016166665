import Swiper from "swiper/bundle";
import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

document.addEventListener("DOMContentLoaded", () => {
  if (window.innerWidth < 768) {
    const sliders = document.querySelectorAll(".content-logo-grid__controller");
    sliders.forEach((slider) => {
      const container = slider.closest(".content-logo-grid");
      new Swiper(slider, {
        direction: "horizontal",
        loop: false,
        watchOverflow: true,
        autoplay: false,
        speed: 750,
        spaceBetween: 20,
        slidesPerView: 1,
        slidesPerGroup: 1,
        grid: {
          rows: 4,
          fill: "column",
        },
        navigation: {
          nextEl: container.querySelector(".swiper-button-next"),
          prevEl: container.querySelector(".swiper-button-prev"),
        },
        pagination: false,
      });
    });
  }
});
