document.addEventListener("DOMContentLoaded", function () {
  const navItems = document.querySelectorAll(".tms-tabs__nav-li");
  const tabItems = document.querySelectorAll(".tms-tabs-item");

  navItems.forEach((nav, index) => {
    nav.addEventListener("click", function () {
      if (window.innerWidth > 1000) {
        navItems.forEach((n, i) => {
          n.classList.remove("active");
          if (tabItems[i]) {
            tabItems[i].classList.remove("active");
          }
        });
        nav.classList.add("active");
        if (tabItems[index]) {
          tabItems[index].classList.add("active");
        }
      }
    });
  });

  if (window.innerWidth > 1000 && navItems.length && tabItems.length) {
    navItems[0].classList.add("active");
    tabItems[0].classList.add("active");
  }

  const mobileTabTitles = document.querySelectorAll(
    ".tms-tabs__tab--mobile .tms-tabs__tab-title"
  );
  const mobileTabContents = document.querySelectorAll(
    ".tms-tabs__tab--mobile .tms-tabs__content"
  );

  mobileTabTitles.forEach((tabTitle, index) => {
    tabTitle.addEventListener("click", () => {
      if (window.innerWidth <= 1000) {
        const content = mobileTabContents[index];
        const tabContainer = tabTitle.closest(".tms-tabs__tab--mobile-item");
        if (content.style.maxHeight && content.style.maxHeight !== "0px") {
          content.style.maxHeight = null;
          tabTitle.classList.remove("active");
          if (tabContainer) {
            tabContainer.classList.remove("active");
          }
          content.classList.remove("active");
        } else {
          mobileTabContents.forEach((el, i) => {
            el.style.maxHeight = null;
            mobileTabTitles[i].classList.remove("active");
            const container = mobileTabTitles[i].closest(
              ".tms-tabs__tab--mobile-item"
            );
            if (container) {
              container.classList.remove("active");
            }
            el.classList.remove("active");
          });
          content.style.maxHeight = content.scrollHeight + "px";
          tabTitle.classList.add("active");
          if (tabContainer) {
            tabContainer.classList.add("active");
          }
          content.classList.add("active");
        }
      }
    });
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1000) {
      mobileTabContents.forEach((content, i) => {
        content.style.maxHeight = null;
        mobileTabTitles[i].classList.remove("active");
        const container = mobileTabTitles[i].closest(
          ".tms-tabs__tab--mobile-item"
        );
        if (container) {
          container.classList.remove("active");
        }
        content.classList.remove("active");
      });
      navItems.forEach((nav, i) => {
        nav.classList.remove("active");
        if (tabItems[i]) {
          tabItems[i].classList.remove("active");
        }
      });
      if (navItems.length && tabItems.length) {
        navItems[0].classList.add("active");
        tabItems[0].classList.add("active");
      }
    } else {
      mobileTabContents.forEach((content) => {
        if (content.style.maxHeight) {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  });
});
