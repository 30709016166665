document.addEventListener("DOMContentLoaded", function () {
  const tabNavItems = document.querySelectorAll(
    ".content-tabs-left-image-text-right__nav-li"
  );
  const tabContents = document.querySelectorAll(
    ".content-tabs-left-image-text-right__tab"
  );
  const tabContentMobileItems = document.querySelectorAll(
    ".content-tabs-left-image-text-right__tab--mobile .content-tabs-left-image-text-right__tab-title"
  );
  const tabContentMobiles = document.querySelectorAll(
    ".content-tabs-left-image-text-right__tab--mobile .content-tabs-left-image-text-right__content"
  );

  tabNavItems.forEach((tabNav, index) => {
    tabNav.addEventListener("click", () => {
      if (window.innerWidth > 1000) {
        tabNavItems.forEach((item, i) => {
          item.classList.remove("active");
          tabContents[i].classList.remove("active");
        });
        tabNav.classList.add("active");
        tabContents[index].classList.add("active");
      }
    });
  });

  if (
    window.innerWidth > 1000 &&
    tabNavItems.length > 0 &&
    tabContents.length > 0
  ) {
    tabNavItems[0].classList.add("active");
    tabContents[0].classList.add("active");
  }

  tabContentMobileItems.forEach((tabTitle, index) => {
    tabTitle.addEventListener("click", () => {
      if (window.innerWidth <= 1000) {
        const content = tabContentMobiles[index];
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
          tabTitle.classList.remove("active");
          content.classList.remove("active");
        } else {
          tabContentMobiles.forEach((el, i) => {
            el.style.maxHeight = null;
            tabContentMobileItems[i].classList.remove("active");
            el.classList.remove("active");
          });
          content.style.maxHeight = content.scrollHeight + "px";
          tabTitle.classList.add("active");
          content.classList.add("active");
        }
      }
    });
  });

  window.addEventListener("resize", () => {
    if (window.innerWidth > 1000) {
      tabContentMobiles.forEach((content, i) => {
        content.style.maxHeight = null;
        tabContentMobileItems[i].classList.remove("active");
        content.classList.remove("active");
      });
      tabNavItems.forEach((tab, i) => {
        tab.classList.remove("active");
        tabContents[i].classList.remove("active");
      });
      if (tabNavItems.length > 0 && tabContents.length > 0) {
        tabNavItems[0].classList.add("active");
        tabContents[0].classList.add("active");
      }
    } else {
      tabContentMobiles.forEach((content, index) => {
        if (content.style.maxHeight) {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  });
});
