import $ from "jquery";

$(function () {
  const windowWidth = $(window).width();

  $(".tms-blocks-hero-2columns").each((index, blockElem) => {
    const align = $(blockElem).data("align");
    let heroHeight;
    const heroHeightAttr = $(blockElem).data("hero-height");

    if (windowWidth < 1024) {
      //mobile and tablet will have the column height smaller
      heroHeight = eval(heroHeightAttr / 1.5);
    } else {
      heroHeight = heroHeightAttr;
    }

    //adding align class to the parent div
    $(blockElem).addClass("align" + (align ?? "full"));

    const elemColumnImg = $(blockElem).find(
      ".wp-block-tms-blocks-hero-2columns .wp-block-column.hero-2columns-column-img"
    );
    elemColumnImg.css("height", heroHeight + "px");
    const elemImage = $(blockElem).find(
      ".wp-block-tms-blocks-hero-2columns .wp-block-column.hero-2columns-column-img img"
    );
    elemImage.css("height", heroHeight + "px");
    const elemColumnContent = $(blockElem).find(
      ".wp-block-tms-blocks-hero-2columns .wp-block-column.hero-2columns-column-content"
    );
    elemColumnContent.css("min-height", heroHeight + "px");
  });
});
